/* Chart.css */

.TVChartContainer2 {
    height: calc(100% - 30px);
}

.TVChartContainer2 > iframe {
    height: 100% !important;
}

.html.theme-dark .chart-page .chart-container-border {
    background: #000 !important;
}

.chart-page .context-menu {
    background: #000 !important;
}

/* Ensure black background during loading */
.TVChartContainer2,
.TVChartContainer2 > div,
.TVChartContainer2 > div > iframe {
    background-color: #000 !important;
}

