/* Trades.css */

.trades-container {
}

.trades-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.trade-item {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
}

.trade-item:nth-child(even) {
    background-color: #101214;
}

.price,
.amount,
.asset {
    margin-right: 10px;
}
