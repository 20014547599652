html {
    overflow: hidden;
}

html,
body,
#root,
.App {
    height: 100%;
    min-height: 100%;
    background: #000;
}

body {
    background: #000;
}

.App,
.modal_dialog {
    font-family: 'ProtoMono', Arial;
}

.modal_dialog {
    z-index: 101;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.metamask-dialog {
    height: 600px;
}

.components-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: start;
    height: 560px;
    /*max-height: 600px;*/
    overflow: hidden; /* Hide any overflow */
}

.block {
    display: block;
    /*justify-content: space-around;
  align-items: start;
  height: 100vh; !* Adjust the height to full viewport *!
  overflow: hidden; !* Hide any overflow *!*/
}

.OrderBook,
.Trades {
    max-height: 100%; /* Ensure components do not exceed screen height */
    overflow-y: auto; /* Enable scrolling if content overflows */
    width: 45%; /* Adjust width as per requirement */
}

.left-side {
    display: flex;
    flex-direction: column;
    width: 50%; /* Adjust the width as needed */
}

.order-book-container,
.trades-container {
    height: 50%; /* Adjust the height as needed */
    overflow: hidden;
}

.order-book-container,
.trades-desktop {
    height: 50%;
}
.trades-desktop .trades-container {
    height: 100%;
}

.Wallets-container {
    /*margin-right: 20px;*/
}

.connection_topbar {
    background: #ffac5a;
    font-size: 10px;
    text-align: center;
    padding: 10px 0;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
}

input,
select {
    all: unset;
}

.input-text,
.input-select {
    width: 100%;
    display: block;
    color: #fff;
    background: #101214;
    border: 1px solid #25272d;
    padding: 4px 8px;
    box-sizing: border-box;
    transition: all 0.1s ease-in;
    position: relative;
}

.input-select {
    cursor: pointer;
}

.input-select-wrapper {
    position: relative;
}

.input-select-wrapper:after {
    content: '';
    position: absolute;
    right: 10px;
    top: 10px;

    border: solid #fff;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    vertical-align: middle;
    -webkit-transform: rotate(45deg);
}

.input-text::placeholder {
    color: #929397;
}

.input-select:hover,
.input-select:focus,
.input-text:hover,
.input-text:focus {
    border: 1px solid #ffac5a;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.header_logo {
    order: 0;
}

.header_link1 {
    order: 1;
}

.header_link2 {
    order: 2;
}

.header_link3 {
    order: 2;
}

.VersionSwitch-container {
    order: 4;
}

.MarginCurrencies-container {
    order: 5;
}

.NoLiquidations-container {
    order: 6;
}

.Balances-container {
    order: 7;
}

.Wallets-container {
    order: 8;
}

.header-break-column-1380-wallet,
.break-column-1280,
.header-break-column-800 {
    display: none;
}

.trades-mobile {
    display: none;
}

.positions-container-positions {
    width: calc(100vw - 480px);
}

.positions-container-trades {
    width: 480px;
    border-left: 1px solid #25272d;
}

.table-headline {
    display: none;
}

.orderbook-body-centred {
    top: 48%;
    transform: translate(0, -50%);
}

.wallets-dropdown-list {
    overflow-y: scroll;
    max-height: calc(100vh - 300px);
}

.assets-scroll-container-desktop {
    height: 100%;
    padding-bottom: 33px;
}

.assets-container {
    height: 100%;
}

.open-chart-block {
    display: none;
}

@media (min-height: 1000px) and (min-width: 1280px) {
    .App {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
}

@media (min-height: 1000px) {
    .positions-container-wrapper-small {
        flex: 1;
    }

    .wallets-dropdown-list {
        overflow-y: scroll;
        max-height: 600px;
    }
    .tradesorder-container {
        height: 100%;
    }
    .trades-desktop,
    .order-book-container {
        flex: 50% 0 0;
        height: 50%;
    }
    .trades-container {
        height: 100%;
    }

    .components-container {
        max-height: 560px;
        min-height: 560px;
    }

    .orderbook-body-centred {
        top: 50%;
        transform: translate(0, -50%);
    }
}

@media (min-height: 1100px) {
    .components-container {
        max-height: 610px;
        min-height: 610px;
    }
}

@media (max-width: 1380px) {
    .Wallets-container {
        order: 9;
        margin: 0 0 0 auto;
        border: none !important;
    }

    .selected-wallet {
        background: #000000;
    }

    .Balances-container {
        order: 5;
    }

    .MarginCurrencies-container {
        order: 9;
        width: auto;
        max-width: none;
        margin: 8px 0;
    }

    .NoLiquidations-container {
        padding-right: 24px;
    }

    .header-break-column-1380-wallet {
        display: block;
        order: 6;
        flex-basis: 100%;
        height: 1px;
        background: #25272d;
        margin: 0 0 0;
    }
}

@media (max-width: 1279px) {
    .assets-scroll-container {
        width: 100%;
        overflow-y: scroll;
        border-bottom: 1px solid #25272d;
    }

    .assets-scroll-container-desktop,
    .assets-container {
        height: auto;
        padding-bottom: 0;
    }
}

@media (max-width: 1050px) {
    .chart-resolution {
        display: none;
    }
    .chart-resolution.openChart {
        display: flex;
    }
    .TVChartContainerLite {
        display: none;
    }
    .TVChartContainerLite.openChart {
        display: block;
    }
    .open-chart-block {
        display: block;
    }

    .tw-chart-container {
        /*border-top: 1px solid #25272D;*/
        /*border-bottom: 1px solid #25272D;*/
    }

    .tradesorder-container {
        /*border-top: 1px solid #25272D;*/
    }

    .order-table-headline {
        display: none;
    }
    .table-headline {
        display: none;
    }

    .components-container {
        height: auto !important;
        max-height: none;
    }

    .assets-container {
        order: 1;
    }
    .tw-chart-container {
        order: 4;
        background-color: #000;
        height: calc(100vh - 90px);
        max-height: 500px;
    }
    .tw-chart-container-lite {
        padding-right: 0 !important;
        width: 100%;
        order: 0;
        margin-bottom: 16px;
        height: auto;
    }

    .tw-chart-container-lite.openChart {
        height: calc(100vh - 90px);
    }
    .tradesorder-container {
        height: calc(100vh - 90px);
        max-height: 500px;
    }
    .tradesorder-container {
        order: 5;
    }
    .orderbox-container {
        border-top: 1px solid #25272d;
        order: 7;
        width: 100%;
    }

    .break-column-1050 {
        display: block;
        order: 3;
        flex-basis: 100%;
    }

    .order-table-wrapper.isLite {
        border: none;
        overflow-y: hidden;
        height: auto !important;
        max-height: none !important;
    }
}

@media (max-width: 992px) {
    .Wallets-container {
        order: 12;
        margin-left: auto;
    }

    .Balances-container {
        order: 10;
        margin-left: auto;
        margin-right: 4px;
    }

    .MarginCurrencies-container {
        order: 9;
        margin: 0;
    }

    .header-break-column-1380-wallet {
        display: block;
        order: 8;
        flex-basis: 100%;
        height: 1px;
        background: #25272d;
        margin: 0 0 0;
    }

    .Balances-container {
        order: 10;
        margin: 0 auto;
    }

    .MarginCurrencies-container {
        order: 12;
        margin: 5px 0;
    }

    .header-break-column-1380-wallet,
    .header-break-column-800 {
        display: block;
        order: 8;
        flex-basis: 100%;
        height: 1px;
        background: #25272d;
        margin: 0 0 0;
    }

    .header-break-column-800 {
        order: 11;
    }
}

@media (max-width: 600px) {
    .chart-res-hide-mobile {
        display: none;
    }
}
@media (max-width: 800px) {
    .assets-scroll-container-desktop,
    .MarginCurrencies-container {
        padding-bottom: 20px !important;
    }

    .header-lite .MarginCurrencies-container {
        padding-bottom: 0 !important;
    }

    .header-lite .Wallets-container {
        margin-top: 0;
    }

    .selected-wallet {
        border: none;
    }

    .tw-chart-container,
    .news-carousel-wrapper {
        background: #000;
        margin-top: -20px;
        position: relative;
        z-index: 1;
    }

    .tw-chart-container-lite {
        margin-top: 0;
    }

    .MarginCurrencies-container {
    }

    /*.MarginCurrencies-container::-webkit-scrollbar,*/
    .assets-scroll-container::-webkit-scrollbar {
        position: absolute;
        width: 0 !important;
        height: 0 !important;
    }

    .assets-scroll-container::-webkit-scrollbar-track {
        background: transparent !important;
        border-radius: 0;
    }

    .assets-scroll-container::-webkit-scrollbar-thumb {
        background: transparent !important;
        border-radius: 0;
    }

    .Balances-container {
        width: 100% !important;
        margin-left: 8px;
        margin-right: 8px;
        overflow: hidden;
    }
    .balances-deposit-button {
        margin-left: auto;
    }

    .assets-scroll-container-desktop {
        height: 100%;
    }

    .tradesorder-container {
        width: 100%;
        order: 4;
        height: auto;
    }

    .tw-chart-container {
        height: 320px;
    }

    .tw-chart-container-lite {
        height: auto;
    }

    .tw-chart-container-lite.openChart {
        height: 360px;
    }

    .TVChartContainer {
        height: calc(100% - 30px) !important;
    }

    .TVChartContainerLite {
        height: calc(100% - 110px) !important;
    }

    .order-book-container,
    .trades-container {
        width: 100%;
        border-left: 0;
        min-height: 338px;
    }

    .trades-container {
        min-height: 200px !important;
        height: 200px !important;
    }

    .tw-chart-container {
        width: 100%;
        order: 1;
    }

    .tw-chart-container-lite {
        order: 0;
    }

    .trades-desktop {
        display: none;
    }

    .trades-mobile {
        width: 100%;
        display: block;
        order: 10;
    }

    .order-book-container,
    .trades-container {
        height: 320px; /* Adjust the height as needed */
        overflow: hidden;
    }

    .order-book-container {
        border-bottom: 1px solid #25272d;
    }
}

@media (max-width: 639px) {
    .MarginCurrencies-container {
        width: 100%;
        margin: 5px 8px;
        order: 12;
        padding-bottom: 0 !important;
    }
    .header-break-column-800 {
        display: none;
    }

    .Wallets-container {
        order: 6;
    }
    .Balances-container {
        order: 14;
        padding-bottom: 24px;
    }

    .header-lite .Balances-container {
        padding-bottom: 0;
    }
}

.switch-item-line:before,
.switch-item-line.active div:before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    z-index: 0;
    top: -2px;
    left: 0;
    transform: translate(0);
    background: #25272d;
}

.switch-item-line:first-child:before {
    width: 50%;
    left: auto;
    right: 0;
}

.switch-item-line:first-child div:before {
    display: none;
}

.switch-item-line_dot {
    width: 8px;
    height: 8px;
    background: #000;
    position: absolute;
    z-index: 9;
    margin-top: -6px;
    margin-left: -1px;
}

.switch-item-line_dot_icon {
    width: 11px;
}

.switch-item-line_dot:before {
    display: none !important;
}

.switch-item-line:last-child:before {
    width: 50%;
}

.switch-item-line:after {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background: #25272d;
    box-sizing: border-box;
    position: absolute;
    z-index: 0;
    top: -4px;
    left: calc(50% - 4px);
    transition: transform 0.15s ease-in-out;
}

.switch-item-line:hover:after {
    transform: scale(1.25);
}

.switch-item-line-label {
    color: #929397;
}

.switch-item-line.fill .switch-item-line-label,
.switch-item-line.active .switch-item-line-label {
    color: #fff;
}

.switch-item-line.fill:after {
    background: #fff;
}

.switch-item-line.active:after {
    background: #fff;
    transform: scale(1.25);
    display: none;
}

.switch-item-line.fill:before {
    background: #fff;
}

.switch-item-line.active div:before {
    background: #fff;
    width: 50%;
    left: 0;
}

.Toastify__toast-body {
    font-family: 'ProtoMono';
    font-size: 12px;
}

.react-tooltip {
    margin-top: -4px;
    border-radius: 0 !important;
    background: #262b30 !important;
    opacity: 1 !important;
    padding: 4px 8px !important;
}

.tooltip-label:hover + span {
    display: block;
}

.width-leverage {
    width: 110px;
}

.blinkImage1 {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(60%, -80%);
}

.blinkImage2 {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-175%, -10%);
}

.blinking {
    fill: rgba(255, 255, 255, 0);
    stroke: #ffac5a;
    stroke-opacity: 0.27;
    stroke-width: 0.630746;
    animation-timing-function: ease;
    animation-duration: 200ms;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    transition: fill 300ms ease-in-out, stroke-opacity 300ms ease-in-out, opacity 500ms ease-in-out;
}

@keyframes blink {
    0%,
    100% {
        fill: rgba(255, 255, 255, 0);
        stroke-opacity: 1;
        opacity: 0.5;
    }
    50% {
        fill: #ffac5a;
        stroke-opacity: 0;
        opacity: 1;
    }
}

@media (max-width: 800px) {
    .blinkImage1 {
        left: 50%;
        top: 50%;
        transform: translate(0%, -60%);
    }
}
